<script>
import Layout from "../../layouts/main"
import appConfig from "@/app.config";
    export default {
        page: {
            title: "Team",
            items: [
            {
                text: "XIVDATA",
                href: "/"
            },
            {
                text: "About",
                href: "/"
            },
            {
                text: "Team",
                active: true
            }
        ],
        meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout
  },
    }
</script>
<template>
<Layout>
<div class="row align-items-center">
    <div class="col-sm-6">
        <div class="page-title-box">
            <h4 class="page-title">{{ $t('pagetitle.team') }}</h4>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">XIVDATA</li>
                <li class="breadcrumb-item">{{ $t('messages.about') }}</li>
                <li class="breadcrumb-item active">{{ $t('pagetitle.team') }}</li>
             </ol>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xl-4 col-md-6">
        <div class="card directory-card">
            <div class="card-body">
                <div class="media">
                    <img src="@/assets/images/team/syntafin.png" alt="" class="img-fluid img-thumbnail rounded-circle avatar-lg">
                    <div class="media-body ml-3">
                        <h5 class="text-primary font-size-18 mt-0 mb-1">Syntafin</h5>
                        <p class="font-12 mb-2">Main Developer</p>
                        <p class="mb-0">syntafin@xivdata.com</p>
                    </div>
                    <ul class="list-unstyled social-links float-right">
                        <li><a href="https://github.com/syntafin" class="btn-primary" target="_blank"><i class="fab fa-github"></i></a></li>
                        <li><a href="https://syntafin.dev" class="btn-info" target="_blank"><i class="fas fa-globe"></i></a></li>
                    </ul>
                </div>
                <hr />
                <p class="mb-0">I had the idea since Vekien decided to shutdown XIVDB, after a long time I decided to make this idea real and that was the point this project was born.</p>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-md-6">
        <div class="card directory-card">
            <div class="card-body">
                <div class="media">
                    <img src="@/assets/images/team/spiswel.png" alt="" class="img-fluid img-thumbnail rounded-circle avatar-lg">
                    <div class="media-body ml-3">
                        <h5 class="text-primary font-size-18 mt-0 mb-1">Spiswel</h5>
                        <p class="font-12 mb-2">Translator</p>
                        <p class="mb-0">spiswel@xivdata.com</p>
                    </div>
                    <ul class="list-unstyled social-links float-right">
                        <li><a href="https://github.com/AdagioFantasque" class="btn-primary" target="_blank"><i class="fab fa-github"></i></a></li>
                        <li><a href="https://twitter.com/orchestrion_inc" class="btn-info" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    </ul>
                </div>
                <hr />
                <p class="mb-0">I'm a native french speaker and an avid XIV player. Participating in this project also has deeply enlarged my understanding of website-building.</p>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-md-6">
        <div class="card directory-card">
            <div class="card-body">
                <div class="media">
                    <img src="@/assets/images/team/weneg.png" alt="" class="img-fluid img-thumbnail rounded-circle avatar-lg">
                    <div class="media-body ml-3">
                        <h5 class="text-primary font-size-18 mt-0 mb-1">Weneg</h5>
                        <p class="font-12 mb-2">Developer</p>
                        <p class="mb-0">weneg@xivdata.com</p>
                    </div>
                    <ul class="list-unstyled social-links float-right">
                        <li><a href="https://github.com/Weneg" class="btn-primary" target="_blank"><i class="fab fa-github"></i></a></li>
                        <li><a href="#" class="btn-info" target="_blank"><i class="fas fa-globe"></i></a></li>
                    </ul>
                </div>
                <hr />
                <p class="mb-0">Master of Data and Desaster</p>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-md-6">
        <div class="card directory-card">
            <div class="card-body">
                <div class="media">
                    <img src="@/assets/images/team/introdox.png" alt="" class="img-fluid img-thumbnail rounded-circle avatar-lg">
                    <div class="media-body ml-3">
                        <h5 class="text-primary font-size-18 mt-0 mb-1">Introdox</h5>
                        <p class="font-12 mb-2">Moderator</p>
                        <p class="mb-0">introdox@xivdata.com</p>
                    </div>
                    <ul class="list-unstyled social-links float-right">
                        <li><a href="#" class="btn-primary"><i class="mdi mdi-facebook"></i></a></li>
                        <li><a href="#" class="btn-info"><i class="mdi mdi-twitter"></i></a></li>
                    </ul>
                </div>
                <hr />
                <p class="mb-0"><b>Short::Desc</b></p>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-md-6">
        <div class="card directory-card">
            <div class="card-body">
                <div class="media">
                    <img src="@/assets/images/team/ajura.png" alt="" class="img-fluid img-thumbnail rounded-circle avatar-lg">
                    <div class="media-body ml-3">
                        <h5 class="text-primary font-size-18 mt-0 mb-1">Ajura</h5>
                        <p class="font-12 mb-2">Translator</p>
                        <p class="mb-0">ajura@xivdata.com</p>
                    </div>
                    <ul class="list-unstyled social-links float-right">
                        <li><a href="#" class="btn-primary"><i class="mdi mdi-facebook"></i></a></li>
                        <li><a href="#" class="btn-info"><i class="mdi mdi-twitter"></i></a></li>
                    </ul>
                </div>
                <hr />
                <p class="mb-0"><b>Short::Desc</b></p>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-md-6">
        <div class="card directory-card">
            <div class="card-body">
                <div class="media">
                    <img src="@/assets/images/team/strawberry.png" alt="" class="img-fluid img-thumbnail rounded-circle avatar-lg">
                    <div class="media-body ml-3">
                        <h5 class="text-primary font-size-18 mt-0 mb-1">Strawberry</h5>
                        <p class="font-12 mb-2">Translator</p>
                        <p class="mb-0">strawberry@xivdata.com</p>
                    </div>
                    <ul class="list-unstyled social-links float-right">
                        <li><a href="#" class="btn-primary"><i class="mdi mdi-facebook"></i></a></li>
                        <li><a href="#" class="btn-info"><i class="mdi mdi-twitter"></i></a></li>
                    </ul>
                </div>
                <hr />
                <p class="mb-0"><b>Short::Desc</b></p>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-md-6">
        <div class="card directory-card">
            <div class="card-body">
                <div class="media">
                    <img src="@/assets/images/team/rayzol.png" alt="" class="img-fluid img-thumbnail rounded-circle avatar-lg">
                    <div class="media-body ml-3">
                        <h5 class="text-primary font-size-18 mt-0 mb-1">Rayzol</h5>
                        <p class="font-12 mb-2">Translator</p>
                        <p class="mb-0">rayzol@xivdata.com</p>
                    </div>
                    <ul class="list-unstyled social-links float-right">
                        <li><a href="https://www.facebook.com/Rayzol-Gaming-861257697343868" target="_blank" class="btn-primary"><i class="mdi mdi-facebook"></i></a></li>
                        <li><a href="https://www.twitch.tv/rayzol91" target="_blank" class="btn-info"><i class="mdi mdi-twitch"></i></a></li>
                    </ul>
                </div>
                <hr />
                <p class="mb-0">I'm playing FFXIV since Release. I am happy to support the project with all possible means.</p>
            </div>
        </div>
    </div>
</div>
</Layout>
</template>
